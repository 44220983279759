.wizard {
  min-height: calc(100vh - 53px);
  .content-wrapper {
    min-height: 70vh;
    &.content-wrapper-payment {
      min-height: 78vh;
    }
  }
  &.image-1-background {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../../layout/images/slika_1.JPG") no-repeat;
    background-size: cover;
  }
  &.image-2-background {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../../layout/images/slika_2.JPG") no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: 1199px) {
    min-height: auto;
    .content-wrapper {
      min-height: auto;
    }
    .locations-layout {
      order: 1;
    }
    .specializations-layout {
      order: 2;
    }
    .search-layout {
      order: 3;
      margin: 0 auto;
    }
    .appointments-wrapper {
      order: 3;
    }
    .patient-info-wrapper {
      order: 3;
      margin: 0 auto;
    }
    .payment-form-wrapper {
      order: 3;
      margin: 0 auto;
    }
  }
}
