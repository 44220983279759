.search-layout {
  small {
    position: absolute;
    bottom: -20px;
    left: 0;

    &.dateTo-error {
      bottom: -31px;
    }
  }

  .procedure-group {
    background-color: $redColor;
    border-radius: 4px;
    padding: 20px 20px 10px 20px;

    .group-item-btn {
      border: 1px solid $whiteColor;
      background-color: transparent;
      box-shadow: none;
      margin: 0 10px 10px 0;
      border-radius: 0;
      text-transform: uppercase;

      &.selected {
        background-color: $primaryColor;
        border-color: transparent;
      }
    }
  }

  @media screen and (min-width: 993px) and (max-width: 1070px) {
    width: 70%;
  }

  @media screen and (max-width: 992px) {
    width: 70%;
  }

  @media screen and (max-width: 575px) {
    width: 90%;
    .procedure-group {
      .group-item-btn {
        width: 100%;
        display: block;
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
