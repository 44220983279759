.patient-info-layout {
  .patient-info-title {
    font-size: px_to_rem(20);
    line-height: px_to_rem(30);
    font-weight: bold;
    color: $blackColor;
  }
  small {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}
