@function px_to_rem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.red {
  color: $redBackgroundColor;
}

.bg-red {
  background-color: $redColor;
}

.green {
  color: $primaryColor;
}
