.processing-payment-popup {
  i {
    font-size: 5rem;
    color: $primaryColor;
  }

  .processing-payment-popup--title {
    font-size: px_to_rem(40);
    line-height: px_to_rem(55);
    letter-spacing: 0;
    color: $blackColor;
  }

  .processing-payment-popup--text {
    font-size: px_to_rem(24);
    line-height: px_to_rem(33);
    letter-spacing: 0;
    color: $grayTextColor;
  }
  
  @media screen and (max-width: 480px) {
    .processing-payment-popup--title {
      font-size: px_to_rem(28);
    }
    .processing-payment-popup--text {
      font-size: px_to_rem(20);
    }
  }
}
