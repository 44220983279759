.wizard-nav {
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  .wizard-nav__steps {
    &.p-steps {
      .p-steps-item {
        .p-menuitem-link {
          background: $transparent;
          pointer-events: none;
          .p-steps-number {
            border: 2px solid $blackColor;
            color: $blackColor;
            font-weight: 700;
          }
          .p-steps-title {
            color: $blackColor;
            font-weight: 700;
          }
        }

        &.p-highlight {
          .p-steps-number {
            color: $whiteColor !important;
            background: $primaryColor;
            border-color: $transparent;
          }
          .p-steps-title {
            color: $blackColor;
          }
        }
        &.visited {
          .p-menuitem-link {
            .p-steps-number {
              border: 2px solid $primaryColor;
              color: $primaryColor;
            }
            .p-steps-title {
              color: $blackColor;
              font-weight: 700;
            }
          }
          &:after {
            border-top: 2px solid $primaryColor;
          }
        }
        &:before {
          display: none;
        }
        &:after {
          content: " ";
          border-top: 2px dashed $blackColor;
          width: 100%;
          top: 50%;
          left: 50%;
          display: block;
          position: absolute;
          margin-top: -1rem;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .nav-btn {
    border-radius: 50%;
    background: $primaryColor;
    border-color: $transparent;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background: $primaryColor;
      border-color: $transparent;
      box-shadow: none;
      outline: none;
    }

    &.nav-btn-forward {
      right: -5%;
    }

    &.nav-btn-back {
      left: -5%;
    }

    @media screen and (max-width: 575px) {
      position: initial;
    }
  }
}
