.appointments-layout {
  .specialisation-title {
    font-size: px_to_rem(20);
    line-height: px_to_rem(30);
    font-weight: bold;
    color: $blackColor;
  }
  .p-accordion.appointments-accordion {
    .p-accordion-tab {
      box-shadow: 0px 3px 6px $shadowColor;
      border-radius: 10px;
      margin-bottom: px_to_rem(30);
    }
    .p-accordion-header {
      .p-accordion-header-link {
        border: 1px solid $borderColor;
        border-radius: 10px;
        background: $whiteColor;
        color: $blackColor;

        .p-accordion-toggle-icon {
          position: absolute;
          margin-right: 0;
          right: px_to_rem(30);
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.p-highlight {
        .appointment-accordion-header {
          padding-bottom: 0 !important;
        }
        .p-accordion-header-link {
          border-radius: 10px 10px 0 0;
        }
      }
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover,
    .p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: $whiteColor;
        border-color: $borderColor;
        color: $blackColor;
        outline: none;
        border-bottom: 0;
        box-shadow: none;
      }
    }

    .p-accordion-content {
      border-color: $borderColor;
      padding-top: 0;
    }
  }

  .p-scrollpanel-content {
    border-top: 1px solid rgba(80, 80, 80, 0.5);
    padding-top: px_to_rem(20) !important;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background-color: $scrollPanelBarColor;
    opacity: 1;
    transition: background-color 0.2s;
    width: 5px;
  }

  .appointment-item {
    cursor: pointer;

    &:hover {
      background: $lightGray;
    }

    &:nth-child(even) {
      background: $grayBackgroundColor;
      &:hover {
        background: $lightGray;
      }
    }

    .appointment-time,
    .appointment-doctor {
      color: $blackColor;
    }

    &.selected {
      background: $primaryColor;
      .appointment-time,
      .appointment-doctor {
        color: $whiteColor;
      }
      &:hover {
        background: $primaryColor;
      }
    }

    @media screen and (max-width: 570px) {
      .appointment-time,
      .appointment-doctor {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
}
