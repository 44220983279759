.appointment-info {
  box-shadow: 0 3px 6px $shadowColor;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: px_to_rem(24);
  color: $blackColor;
  text-align: left;
  font-size: px_to_rem(16);
  background-color: $whiteColor;

  .appointment-info__item {
    &:first-child {
      border-right: 1px solid $grayTextColor;

      @media screen and (max-width: 481px) {
        border-right: 0;
        border-bottom: 1px solid $grayTextColor;
        width: 100%;
        padding-bottom: px_to_rem(10);
        margin-bottom: px_to_rem(10);
      }
    }
    @media screen and (max-width: 481px) {
      padding-left: 0 !important;
    }
  }
  .appointment-info__service-item {
    border-top: 1px solid rgba(80, 80, 80, 0.5);
  }
}
