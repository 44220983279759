.payment-layout {
  .p-input-icon-left {
    > .p-inputtext {
      height: 50px;
    }
    > i:first-of-type {
      color: $blackColor;
    }
  }
  .input-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: $blackColor;
  }
  .card-number-wrapper,
  .cvc-wrapper {
    height: 50px;
    border: 1px solid $blackColor;
    border-radius: 4px;
  }
  .card-type {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    height: 22px;
    width: 32px;
    pointer-events: none;
    opacity: 0;

    &.visa {
      background: url(https://api.allsecure.xyz/download/logos/visa.svg) no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.mastercard {
      background: url("../../../infrastructure/images/master.svg") no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.diners {
      background: url(https://api.allsecure.xyz/download/logos/diners.svg) no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.amex {
      background: url(https://api.allsecure.xyz/download/logos/amex.svg) no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.jcb {
      background: url(https://api.allsecure.xyz/download/logos/jcb.svg) no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.discover {
      background: url(https://api.allsecure.xyz/download/logos/discover.svg) no-repeat center right;
      opacity: 1;
      background-size: 30px 18px;
    }

    &.null {
      opacity: 0;
    }

    &.false {
      background: url(https://api.allsecure.xyz/download/logos/error.svg) no-repeat center right;
      opacity: 1;
      background-size: 18px 18px;
    }
  }
  .error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    height: 22px;
    width: 32px;
    pointer-events: none;
    background: url(https://api.allsecure.xyz/download/logos/error.svg) no-repeat center right;
    opacity: 0;
    background-size: 18px 18px;

    &.visible {
      opacity: 1;
    }
  }
}
