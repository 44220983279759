@import "../overrides/layout_variables";
@import "./utils";
@import "./wizard_nav";
@import "./static-header";
@import "./forms";
@import "./appointments";
@import "./patient-info";
@import "./wizard";
@import "./wizard-footer";
@import "./services";
@import "./appointment-info-box";
@import "./error";
@import "./search";
@import "./payment";
@import "./confirm-popup";
@import "./payment-processing-popup";
@import "./success";
@import "./calendar";
@import "./error-canceled-transactions";
@import "./locations-specializations";
@import "./footer";
@import "./dialog";
@import "./warning";

.p-component {
  font-family: inherit !important;
}

.p-multiselect-panel {
  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      .p-multiselect-item {
        .p-checkbox {
          .p-checkbox-box {
            border-radius: 0;
            border-color: $redColor;
            border-width: 1px;

            &.p-highlight {
              background-color: $redColor;
            }
          }
        }

        &.p-disabled {
          .p-checkbox.p-component {
            display: none;
          }
          span {
            color: $blackColor;
            opacity: 1;
          }
        }
      }
    }
  }
}

.phone-link {
  color: inherit;
}

.notInFunctionPopup {
  font-size: px_to_rem(20);
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print {
    &.success-layout {
      .success-info-box {
        width: 100% !important;
        border: 0;
        box-shadow: none;
      }
    }
  }
}
