.error-layout {
  min-height: calc(100vh - 53px);

  .error-info-box {
    box-shadow: 0 3px 6px $shadowColor;
    border: 1px solid $borderColor;
    border-radius: 10px;

    .error-info-box--title {
      font-size: px_to_rem(40);
      line-height: px_to_rem(55);
      font-weight: 600;
      letter-spacing: 0;
      color: $redColor;
    }

    .error-info-box--items {
      font-size: px_to_rem(24);
      line-height: px_to_rem(33);
      letter-spacing: 0;
      color: $grayTextColor;
    }
  }
}
