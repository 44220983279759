.locations-layout,
.specializations-layout {
  .p-accordion {
    display: none;
    max-width: 83%;

    @media screen and (max-width: 1199px) {
      display: block;
    }

    @media screen and (max-width: 992px) {
      max-width: 50%;
    }

    @media screen and (max-width: 850px) {
      max-width: 80%;
    }

    @media screen and (max-width: 560px) {
      max-width: 100% !important;
      width: 100% !important;
    }

    .p-accordion-tab {
      box-shadow: 0 3px 6px $shadowColor;
      border-radius: 10px;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        border: 0;
        border-radius: 4px;
        background: $primaryColor;
        color: $whiteColor;
        padding-top: 0;
        padding-bottom: 0;
        letter-spacing: -0.23px;
        text-shadow: 0 8px 20px $textShadowColor;
        font-size: px_to_rem(13);

        .p-accordion-toggle-icon {
          position: absolute;
          margin-right: 0;
          right: px_to_rem(30);
          color: $whiteColor;
        }

        &:focus {
          box-shadow: none;
        }

        @media screen and (max-width: 1200px) {
          font-size: px_to_rem(10);
        }
      }

      &.p-highlight {
        .appointment-accordion-header {
          padding-bottom: 0 !important;
        }
      }
    }

    //.p-accordion-header:not(.p-highlight):not(.p-disabled):hover,
    .p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: $primaryColor;
        border-color: $transparent;
        color: $whiteColor;
        outline: none;
        border-bottom: 0;
        box-shadow: none;
        letter-spacing: -0.23px;
        text-shadow: 0 8px 20px $textShadowColor;
        border-radius: 4px 4px 0 0;
      }
    }

    .p-accordion-content {
      border-color: $transparent;
      padding-top: 0;
      background-color: $primaryColor;
      color: $whiteColor;
      letter-spacing: -0.23px;
      text-shadow: 0 8px 20px $textShadowColor;

      span {
        font-size: px_to_rem(18);
      }

      ul {
        list-style-type: "- ";

        li {
          padding-bottom: px_to_rem(12);
        }
      }
    }
  }

  .green-box {
    background-color: $primaryColor;
    letter-spacing: -0.23px;
    text-shadow: 0 8px 20px $textShadowColor;
    min-height: 560px;
    display: none;

    span {
      font-size: px_to_rem(18);
    }

    ul {
      list-style-type: "- ";

      li {
        padding-bottom: px_to_rem(12);
      }
    }

    @media screen and (max-width: 1600px) {
      min-height: 100%;
      font-size: px_to_rem(13);
      span {
        font-size: px_to_rem(14);
      }
      h1 {
        font-size: px_to_rem(15);
      }
    }

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
}

.specializations-layout {
  .green-box {
    display: none;

    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }
}
