.content-wrapper,
.wizard-footer,
.success-layout,
.error-layout {
  .p-inputtext,
  .p-multiselect,
  .p-dropdown {
    border: 1px solid $blackColor;
    border-radius: 4px;
    background-color: $transparent;
    color: $blackColor;
    font-family: inherit !important;

    &:enabled:hover {
      border-color: $blackColor;
    }

    &::placeholder,
    .p-placeholder {
      color: $blackColor;
      opacity: .7;
    }
  }

  .p-calendar-w-btn-right {
    .p-inputtext {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      border-right: 0;
    }
    .p-datepicker-trigger {
      background: $transparent;
      border-color: $blackColor;
      border-left: 0;
      color: $blackColor;
      width: 2rem;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background: $transparent;
        border-color: $blackColor;
        border-left: 0;
      }
    }

    &.p-invalid {
      .p-datepicker-trigger {
        border-color: $invalidDatePickerColor;
      }
    }
  }

  .p-multiselect {
    &:not(.p-disabled):hover {
      border-color: $blackColor;
    }
  }

  .p-multiselect-panel {
    .p-multiselect-items-wrapper {
      .p-multiselect-items {
        .p-multiselect-item {
          .p-checkbox {
            .p-checkbox-box {
              border-radius: 0;
              border-color: $redColor;
            }
          }
        }
      }
    }
  }

  .p-button {
    background: $primaryColor;
    border-color: $transparent;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background: $primaryColor;
      border-color: $transparent;
      box-shadow: none;
      outline: none;
    }

    &.p-button-outlined {
      background-color: $transparent;
      border: 2px solid $primaryColor;
      color: $primaryColor;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: $transparent;
        border: 2px solid $primaryColor;
        color: $primaryColor;
      }
    }

    .p-button-icon-left {
      margin-right: 0;
    }
  }
  .date-item,
  .time-item {
    max-width: 49%;

    @media screen and (max-width: 800px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .p-dropdown {
    .p-inputtext {
      border: 0;
    }
  }
}
