.services-layout {
  .services-box {
    box-shadow: 0 3px 6px $shadowColor;
    background-color: $transparent;
    border-radius: 4px;
    .p-panel-header {
      border: 1px solid $borderColor;
      background-color: $transparent;
      border-radius: 4px 4px 0 0;
      padding: px_to_rem(12);
    }
    .p-panel-content {
      border: 1px solid $borderColor;
      border-top: 0;
      padding: px_to_rem(30) px_to_rem(12);
    }
    .specialisation-title {
      font-size: px_to_rem(20);
      line-height: px_to_rem(30);
      font-weight: bold;
      color: $blackColor;
    }
    .p-inputtext {
      border-radius: 4px;
    }
    .service-item {
      cursor: pointer;

      &:hover {
        background: $lightGray;
      }

      &:nth-child(even) {
        background: $grayBackgroundColor;
        &:hover {
          background: $lightGray;
        }
      }

      .service-name,
      .service-price {
        color: $blackColor;
      }

      &.selected {
        background: $primaryColor;
        .service-name,
        .service-price {
          color: $whiteColor;
        }
        &:hover {
          background: $primaryColor;
        }
      }
    }
    .p-scrollpanel .p-scrollpanel-bar {
      background-color: $scrollPanelBarColor;
      opacity: 1;
      transition: background-color 0.2s;
      width: 5px;
    }
  }
}
