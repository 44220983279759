.error-wrapper {
  width: 100%;
  height: calc(100vh - 53px);
  color: $blackColor;
  letter-spacing: 0;

  .error-wrapper__title {
    font-size: px_to_rem(160);
    line-height: px_to_rem(219);
  }

  .error-wrapper__subtitle {
    font-size: px_to_rem(40);
    line-height: px_to_rem(55);
  }
  .error-wrapper__text {
    font-size: px_to_rem(22);
    line-height: px_to_rem(30);
  }

  .error-wrapper__link {
    text-decoration: none;
    color: $primaryColor;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }
}
